import api from "../api";
import { BrandSessionDto, SaveBasketSessionCommand } from "../swagger";

export const SessionService = {
  saveSession,
  saveBasketSession,
};

async function saveSession(sessionId: string, input: BrandSessionDto) {
  return api.post(
    process.env.REACT_APP_API_URL +
      `/api/session/SaveSessionasync/${sessionId}`,
    { ...input }, { withCredentials: true }
  );
}
async function saveBasketSession(
  sessionId: string,
  input: SaveBasketSessionCommand
) {
  return api.post(
    process.env.REACT_APP_API_URL +
      `/api/session/SaveBasketSessionasync/${sessionId}`,
    { ...input }, { withCredentials: true }
  );
}
