import { ProductConstants } from '../constants'
import { GarmentTypeDto, ProductAttribute, ProductDetailDto } from '../../swagger';
import { logEvent } from '../../tracker';
const productState = {
  loading: false,
  isLoaded:false,
  product: {} as ProductDetailDto,
  productAttributes: Array<ProductAttribute>(),
  attributeCode: null,
  error: '',
  stayAtSamePage:false,
  garmentType:GarmentTypeDto,
}

export function ProductReducer(state = productState, action: any) {
  switch (action.type) {
    case ProductConstants.PRODUCT_REQUEST:
      return { loading: true,stayAtSamePage:false }
    case ProductConstants.PRODUCT_SUCCESS: {
      const selectedProductMeasurements = action.product?.hasAttributes
        ? action.product?.productAttributes[0].productMeasurements
        : action.product?.productMeasurements

        const attributeCode = action.product?.hasAttributes
        ? action.product.productAttributes[0].productCode
        : null

       const product = {
          ...action.product,
          productMeasurements:selectedProductMeasurements
      } as ProductDetailDto;

      logEvent("saiz","saiz_recommender-visibility",`${product?.isActive}`)
      return {
        loading: false,
        product: product,
        productAttributes: action.product.productAttributes || [],
        attributeCode,
        isLoaded:true,
        stayAtSamePage:false,
        garmentType: action.product.garmentType 
      }
    }
    case ProductConstants.PRODUCT_SELECT_FROM_ATTRIBUTES: {
      const selectedAttribute = state.productAttributes.find(
        (item: any) => item.productCode === action.productCode,
      ) as ProductAttribute;
      
      const product = {
         ...state.product,
          garmentType:selectedAttribute.garmentType,
          productMeasurements:selectedAttribute.productMeasurements
      } as ProductDetailDto;

      return {
        ...state,
        product: product,
        productAttributes: state.product.productAttributes || [],
        attributeCode:action.productCode,
        stayAtSamePage:true,
        isLoaded:true,
        garmentType:product.garmentType
      }
    }
    case ProductConstants.PRODUCT_FAILURE:
      return { loading: true, error: action.error,isLoaded:false , stayAtSamePage:false}
    case ProductConstants.CLEAR:
      return productState;
    default:
      return state
  }
}
export const productSelector = (state: any) => state.productState
