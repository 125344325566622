import api from "../api";

export const ProductService = {
  getProductDetail,
};
async function getProductDetail(brandCode: string, productCode: string) {
  productCode = productCode.replaceAll("#", "-");
  return api.get(
    `${process.env.REACT_APP_API_URL}/api/product/GetProductForWidget/${brandCode}/${productCode}`, { withCredentials: true }
  );
}
