import api from "../api";
import { BodyMeasurementRequestDto } from '../swagger'
export const CalculatorService = {
  recommendationAsync
}

async function recommendationAsync(sessionId:string,input:BodyMeasurementRequestDto) {
  return api.post(`${process.env.REACT_APP_API_URL}/api/recommender/RecommendationAsync`,
    { ...input }, { withCredentials: true }
  );
}
