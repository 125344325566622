import axios from "axios";
import { getCookie } from "../helpers";
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "saiz-api-key": process.env.REACT_APP_SAIZ_API_KEY,
  },
  xsrfCookieName: process.env.REACT_APP_XSRF_TOKEN,
  xsrfHeaderName: process.env.REACT_APP_X_XSRF_TOKEN,

});
const send = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "saiz-api-key": process.env.REACT_APP_SAIZ_API_KEY,
  },
  xsrfCookieName: process.env.REACT_APP_XSRF_TOKEN,
  xsrfHeaderName: process.env.REACT_APP_X_XSRF_TOKEN,
});

// Add a request interceptor
http.interceptors.request.use(
  function (config: any) {
    config.headers[process.env.REACT_APP_X_XSRF_TOKEN ?? "X_XSRF_TOKEN"] = getCookie(process.env.REACT_APP_XSRF_TOKEN);
    
    return config;
  },
  (error: any) => {
    // Do something with request error
    Promise.reject(error);
  }
);
// Add a response interceptor
http.interceptors.response.use(
  function (response: any) {
    return response.data;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);
const api = {
  get: http.get,
  post: http.post,
  put: http.put,
  delete: http.delete,
  patch: http.patch,
  send: send.post
};

export default api;